import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";
// import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import NavPills from "components/NavPills/NavPills.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Button from "components/CustomButtons/Button.js";
import { Domain } from "Domain";
// import axios from "axios";
// import Danger from "components/Typography/Danger";
import LoadingOverlay from "react-loading-overlay";
// import ImageUpload from "components/CustomUpload/ImageUpload.js";
// import Accordion from "components/Accordion/Accordion";
// import Pagination from "components/Pagination/Pagination";
import axios from "axios";
// import Success from "components/Typography/Success";

import SnackbarContent from "components/Snackbar/SnackbarContent";
import SingleSelect from "components/SingleSelect";
import Danger from "components/Typography/Danger";
// import AttachFile from "@material-ui/icons/AttachFile";
// import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";

// import Data from "./Data.json";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TicketStatusDev() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const userdetails = window.localStorage.getItem("userdetails");
  const UserDetails = JSON.parse(userdetails);
  // console.log("userdetails:", UserDetails.FullName);
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [Raised, setRaised] = useState([]);
  const [Assigned, setAssigned] = useState([]);
  const [Working, setWorking] = useState([]);
  const [Completed, setCompleted] = useState([]);
  const [loading, setLoading] = useState(true);
  const [RaisedEmpty, setRaisedEmpty] = useState(true);
  const [AssignedEmpty, setAssignedEmpty] = useState(true);
  const [WorkingEmpty, setWorkingEmpty] = useState(true);
  const [CompletedEmpty, setCompletedEmpty] = useState(true);
  const [viewData, setviewData] = useState(false);
  const [edit, setEdit] = useState("");
  const [DevError, setError] = useState(false);
  const [TicketDetials, setTicketDetails] = useState([]);
  const [Pending, setPending] = useState([]);
  const [PendingEmpty, setPendingEmpty] = useState(true);
  const [refresh, setRefresh] = useState();
  useEffect(() => {
    console.log(RaisedData, RaisedEmpty);
  }, []);
  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  //Deleted Notification Trigger
  const showDeletedNotification = () => {
    if (!deleted) {
      setDeleted(true);
      setTimeout(function () {
        setDeleted(false);
      }, 3000);
    }
  };
  //Refresh Function
  const HandleRefresh = () => {
    if (!refresh) {
      setRefresh(true);
    } else {
      setRefresh(false);
    }
  };
  //To get Raised Tickets list from database
  useEffect(() => {
    let formData = new FormData();

    formData.append("Status", "Raised");
    formData.append("DeveloperId", UserDetails.id);
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Raised Response:", response);
        if (response.data.success) {
          if (response.data.TotalCount != 0) {
            setRaised(response.data.data);
            setLoading(false);
            setRaisedEmpty(false);
          } else {
            setRaisedEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);

  //To get Assigned Tickets list from database
  useEffect(() => {
    let formData = new FormData();

    formData.append("DeveloperId", UserDetails.id);
    formData.append("Status", "Assigned");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Assigned Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setAssigned(response.data.data);
            setAssignedEmpty(false);
            setLoading(false);
          }
        } else {
          setAssignedEmpty(true);
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);

  //To get Working Tickets list from database
  useEffect(() => {
    let formData = new FormData();

    formData.append("DeveloperId", UserDetails.id);
    formData.append("Status", "Working");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Working Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setWorking(response.data.data);
            setWorkingEmpty(false);
            setLoading(false);
          }
        } else {
          setWorkingEmpty(true);
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);

  //To get Completed Tickets list from database
  useEffect(() => {
    let formData = new FormData();

    formData.append("DeveloperId", UserDetails.id);
    formData.append("Status", "Completed");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Completed Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setCompleted(response.data.data);
            setLoading(false);
            setCompletedEmpty(false);
          }
        } else {
          setCompletedEmpty(true);
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);
  //To get Pending Tickets list from database
  useEffect(() => {
    let formData = new FormData();

    formData.append("Status", "Pending");
    formData.append("DeveloperId", UserDetails.id);
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Pending Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setPending(response.data.data);
            setLoading(false);
            setPendingEmpty(false);
          } else {
            setPendingEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);
  const PendingData = Pending.map((d) => ({
    Status: (
      <>
        <SnackbarContent color="danger" message="Pending">
          {" "}
        </SnackbarContent>
      </>
    ),
    DeveloperName: (
      <>
        <SnackbarContent color="warning" message={d.DeveloperName}>
          {" "}
        </SnackbarContent>
      </>
    ),
    Date: d.CreateDate.split(" ")[0],
    Id: d.id,
    Project: d.Project,
    Subject: d.Subject,
    Description: d.Description.slice(0, 100),
  }));
  //Maping Data to tables
  const RaisedData = Raised.map((d) => ({
    Status: (
      <>
        <SnackbarContent color="warning" message={d.Status}>
          {" "}
        </SnackbarContent>
      </>
    ),
    Date: d.CreateDate.split(" ")[0],
    Time: d.CreateDate.split(" ")[1],
    Id: d.id,
    FullName: d.FullName,
    Project: d.Project,
    Subject: d.Subject,
    Description: d.Description.slice(0, 100),
    Image: d.Image,
  }));
  const AssignedData = Assigned.map((d) => ({
    Status: (
      <>
        {d.Flag == "Important" ? (
          <>
            {" "}
            <SnackbarContent color="danger" message={d.Status}>
              {" "}
            </SnackbarContent>
          </>
        ) : (
          <>
            <SnackbarContent color="primary" message={d.Status}>
              {" "}
            </SnackbarContent>
          </>
        )}
      </>
    ),
    DeveloperName: (
      <>
        <SnackbarContent color="warning" message={d.DeveloperName}>
          {" "}
        </SnackbarContent>
      </>
    ),
    Date: d.CreateDate.split(" ")[0],
    Time: d.CreateDate.split(" ")[1],
    Id: d.id,
    FullName: d.FullName,
    Project: d.Project,
    Subject: d.Subject,
    Description: d.Description.slice(0, 100),
    Image: d.Image,
  }));
  const WorkingData = Working.map((d) => ({
    Status: (
      <>
        <SnackbarContent color="info" message={d.Status}>
          {" "}
        </SnackbarContent>
      </>
    ),
    DeveloperName: (
      <>
        <SnackbarContent color="warning" message={d.DeveloperName}>
          {" "}
        </SnackbarContent>
      </>
    ),
    Date: d.CreateDate.split(" ")[0],
    Time: d.CreateDate.split(" ")[1],
    Id: d.id,
    FullName: d.FullName,
    Project: d.Project,
    Subject: d.Subject,
    Description: d.Description.slice(0, 100),
    Image: d.Image,
  }));
  const CompletedData = Completed.map((d) => ({
    Status: (
      <>
        <SnackbarContent color="success" message="Complete">
          {" "}
        </SnackbarContent>
      </>
    ),
    DeveloperName: (
      <>
        <SnackbarContent color="warning" message={d.DeveloperName}>
          {" "}
        </SnackbarContent>
      </>
    ),
    Date: d.CreateDate.split(" ")[0],
    Time: d.CreateDate.split(" ")[1],
    Id: d.id,
    FullName: d.FullName,
    Project: d.Project,
    Subject: d.Subject,
    Description: d.Description.slice(0, 100),
    Image: d.Image,
  }));
  // =====================================
  function HandleView() {
    if (edit != "") {
      if (viewData) {
        setviewData(false);
        setEdit("");
      } else {
        setviewData(true);
      }
    } else {
      setviewData(false);
    }
  }
  //Edit Button Clicked
  useEffect(() => {
    if (edit != "") {
      let formData = new FormData();

      formData.append("Id", edit);
      axios({
        method: "post",
        url: Domain + "/GetTicketById.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          // console.log("================Response for Edit:", response.data);
          if (response.data.success) {
            setTicketDetails(response.data.data);
            HandleView();
          }
        })
        .catch(function (response) {
          //handle error
          showDeletedNotification();
          console.log("Error:", response);
        });
    }
  }, [edit]);

  //To get Developer list from database
  // useEffect(() => {
  //   let formData = new FormData();
  //   formData.append("Usertype", "Developer");
  //   axios({
  //     method: "post",
  //     url: Domain + "/getall_users.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       // console.log("Response:", response.data);
  //       setDevelopers(response.data);
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log(response);
  //     });
  // }, []);
  // console.log(Developers);
  const StatusList = [
    { value: 1, label: "Working" },
    { value: 2, label: "Completed" },
    { value: 3, label: "Pending" },
  ];

  const [StatusValues, SetStatusValues] = React.useState({
    Id: null,
    label: "",
  });
  // console.log("selected ", StatusValues);
  // console.log("Name  Id:", JSON.parse(userdetails));

  function ChangeStatus() {
    if (StatusValues.Id != null) {
      setError(false);
      let formData = new FormData();
      formData.append("DeveloperId", UserDetails.id);
      formData.append("DeveloperName", UserDetails.FullName);
      formData.append("Id", TicketDetials.id);
      formData.append("Status", StatusValues.Label);
      formData.append("Flag", "");
      axios({
        method: "post",
        url: Domain + "/Assign_toDeveloper.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          if (response.data.success) {
            // console.log("============Status Changed Response:", response.data);
            showSavedNotification();
            HandleView();
          } else {
            HandleView();
            showDeletedNotification;
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          showDeletedNotification;
        });
    } else {
      setError(true);
    }
  }
  // =======================================

  return (
    <>
      <Snackbar
        place="bc"
        color="success"
        icon={AddAlert}
        message="Status Changed Successfully!"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="bc"
        color="danger"
        icon={AddAlert}
        message="Error Please try again!"
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />

      {viewData ? (
        <>
          <Card>
            <GridContainer>
              <GridItem md={12} sm={12} lg={4}>
                <img
                  style={{ maxHeight: "200px" }}
                  src={Domain + "/images/" + TicketDetials.Image}
                  alt=".."
                />
              </GridItem>
              <GridItem md={12} sm={12} lg={6}>
                Name : <b>{TicketDetials.FullName}</b>
                <br />
                Subject : <b>{TicketDetials.Subject}</b> <br />
                Ticket Number : <b>{TicketDetials.id}</b> <br />
                Ticket Status : <b>{TicketDetials.Status}</b> <br />
                Project Name : <b>{TicketDetials.Project}</b> <br />
                Issue Description : <b>{TicketDetials.Description}</b> <br />
                Ticket Raised Date : <b>{TicketDetials.CreateDate}</b> <br />
                <GridItem xs={12} sm={12} md={6}>
                  <SingleSelect
                    noOptionsMessage="Create any course first"
                    placeholder="Select Status"
                    Options={StatusList}
                    setValue={SetStatusValues}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                {DevError ? (
                  <>
                    <Danger>Please Select a Status first</Danger>
                  </>
                ) : (
                  <></>
                )}
                <GridContainer className={classes.pullRight}>
                  <Button round onClick={HandleView}>
                    Go back
                  </Button>
                  <Button color="info" round onClick={ChangeStatus}>
                    Change Status
                  </Button>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </Card>
        </>
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button round onClick={HandleRefresh}>
              Refresh
            </Button>
            <NavPills
              active={0}
              color="info"
              tabs={[
                {
                  tabButton: "Assigned Tickets",
                  tabContent: (
                    <span>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Card>
                            <CardHeader color="info">
                              <h4 className={classes.cardTitleWhite}>
                                List of Current Tickets
                              </h4>
                              <p className={classes.cardCategoryWhite}>
                                Currently working Tickets will be listed below
                              </p>
                            </CardHeader>
                            <CardBody>
                              <LoadingOverlay
                                active={false}
                                spinner
                                text="Please Wait.."
                              >
                                {AssignedEmpty ? (
                                  <p>empty</p>
                                ) : (
                                  <Table
                                    tableHeaderColor="info"
                                    tableHead={[
                                      "Status",
                                      "Assigned To",
                                      "Date",
                                      "Time",
                                      "Ticket Number",
                                      "Name",
                                      "Project",
                                      "Subject",
                                      "Description",
                                      "Image",
                                      "Action",
                                    ]}
                                    tableData={AssignedData}
                                    noAction={false}
                                    setEdit={setEdit}
                                    Images={false}
                                    loading={loading}
                                  />
                                )}
                              </LoadingOverlay>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </span>
                  ),
                },
                {
                  tabButton: "Working Tickets",
                  tabContent: (
                    <span>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Card>
                            <CardHeader color="info">
                              <h4 className={classes.cardTitleWhite}>
                                List of Completed Tickets
                              </h4>
                              <p className={classes.cardCategoryWhite}>
                                Completed Tickets will be listed below
                              </p>
                            </CardHeader>
                            <CardBody>
                              <LoadingOverlay
                                active={false}
                                spinner
                                text="Please Wait.."
                              >
                                {WorkingEmpty ? (
                                  <p>empty</p>
                                ) : (
                                  <>
                                    <Table
                                      tableHeaderColor="info"
                                      tableHead={[
                                        "Status",
                                        "Assigned To",
                                        "Date",
                                        "Time",
                                        "Ticket Number",
                                        "Name",
                                        "Project",
                                        "Subject",
                                        "Description",
                                        "Image",
                                        "Action",
                                      ]}
                                      tableData={WorkingData}
                                      noAction={false}
                                      setEdit={setEdit}
                                      Images={false}
                                      loading={loading}
                                    />
                                  </>
                                )}
                              </LoadingOverlay>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </span>
                  ),
                },
                {
                  tabButton: "Completed Tickets",
                  tabContent: (
                    <span>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Card>
                            <CardHeader color="info">
                              <h4 className={classes.cardTitleWhite}>
                                List of Completed Tickets
                              </h4>
                              <p className={classes.cardCategoryWhite}>
                                Completed Tickets will be listed below
                              </p>
                            </CardHeader>
                            <CardBody>
                              <LoadingOverlay
                                active={false}
                                spinner
                                text="Please Wait.."
                              >
                                {CompletedEmpty ? (
                                  <p>empty</p>
                                ) : (
                                  <Table
                                    tableHeaderColor="info"
                                    tableHead={[
                                      "Status",
                                      "Assigned To",
                                      "Date",
                                      "Time",
                                      "Ticket Number",
                                      "Name",
                                      "Project",
                                      "Subject",
                                      "Description",
                                      "Image",
                                    ]}
                                    tableData={CompletedData}
                                    noAction={true}
                                    setEdit={setEdit}
                                    Images={false}
                                    loading={loading}
                                  />
                                )}
                              </LoadingOverlay>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </span>
                  ),
                },
                {
                  tabButton: "Pending Tickets",
                  tabContent: (
                    <span>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Card>
                            <CardHeader color="info">
                              <h4 className={classes.cardTitleWhite}>
                                List of Pending Tickets
                              </h4>
                              <p className={classes.cardCategoryWhite}>
                                Pending Tickets will be listed below
                              </p>
                            </CardHeader>
                            <CardBody>
                              <LoadingOverlay
                                active={false}
                                spinner
                                text="Please Wait.."
                              >
                                {PendingEmpty ? (
                                  <p>empty</p>
                                ) : (
                                  <Table
                                    tableHeaderColor="info"
                                    tableHead={[
                                      "Status",
                                      "Assigned To",
                                      "Date",
                                      "Ticket Number",
                                      "Project",
                                      "Subject",
                                      "Description",
                                      "Action",
                                    ]}
                                    tableData={PendingData}
                                    noAction={false}
                                    setEdit={setEdit}
                                    Images={false}
                                    loading={loading}
                                  />
                                )}
                              </LoadingOverlay>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </span>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}
