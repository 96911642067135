import Dashboard from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import ClientsList from "views/ClientsList/ClientsList";
import DashboardPage from "views/Dashboard/Dashboard.js";
import DoctorsList from "views/DoctorsList/DoctorsList";
import Modalities from "views/Modalities/Modalities";
// import Staffs from "views/Sfatts/Staffs";
// import TicketStatusAdmin from "views/TicketStatusAdmin/TicketStatusAdmin";
import WorkLog from "views/WorkLog/WorkLog";
import FileCopy from "@material-ui/icons/FileCopy";
import Ballot from "@material-ui/icons/Ballot";
import PriceListPage from "views/PriceListPage/PriceListPage";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/doctors-list",
    name: "Doctors List",
    icon: PeopleIcon,
    component: DoctorsList,
    layout: "/admin",
  },

  {
    path: "/clients-list",
    name: "Clients List",
    icon: PeopleIcon,
    component: ClientsList,
    layout: "/admin",
  },
  {
    path: "/generate-bill",
    name: "Generate Bill",
    icon: "content_paste",
    component: WorkLog,
    layout: "/admin",
  },
  {
    path: "/modalities",
    name: "Modalities",
    icon: Ballot,
    component: Modalities,
    layout: "/admin",
  },
  {
    path: "/price-list",
    name: "Price List",
    icon: FileCopy,
    component: PriceListPage,
    layout: "/admin",
  },
];

export default dashboardRoutes;
