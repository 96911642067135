import React, { useEffect, useState } from "react";

// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// core components
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardBody from "components/Card/CardBody.js";
import PropTypes from "prop-types";

// import Button from "components/CustomButtons/Button.js";
import { Domain } from "Domain";
import axios from "axios";
import LinearIndeterminate from "components/Loader/LinearLoader";
// import CardFooter from "components/Card/CardFooter";
import Danger from "components/Typography/Danger";

export default function Events({ data, Entity, setData, setTotal, loaded }) {
  // const classes = useStyles();
  // const [DoctorDetails, setDoctorDetails] = useState([]);
  const [BodyParts, setBodyParts] = useState(null);
  const [Modalities, setModalities] = useState(null);
  const [set, setSet] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [EntityNotFound, setEntityNotFound] = useState(false);
  // console.log("\n \t DoctorDetails:", DoctorDetails);
  // //console.log("\n \t Modalities:", Modalities);
  // //console.log("\n \t BodyParts:", BodyParts);
  //console.log("DoctorDetails", DoctorDetails);
  // if (DoctorDetails != "") {
  //   //console.log("SetDocPan:", setDocPan("Hii"));
  //   // SetDocPan(DoctorDetails.Pan);
  // }
  // if (DoctorsTDSData != undefined) {
  //   if (DoctorDetails != "") {
  //     DoctorsTDSData.map((d) => {
  //       console.log(
  //         "#############################",
  //         d.Name.replace(/ /g, "")
  //           .replace(/[^a-zA-Z0-9 ]/g, "")
  //           .toLowerCase()
  //       );
  //       if (
  //         d.Name.replace(/ /g, "")
  //           .replace(/[^a-zA-Z0-9 ]/g, "")
  //           .toLowerCase() == DoctorDetails.StringId
  //       ) {
  //         d.Pan = DoctorDetails.Pan;
  //       }
  //     });
  //   }
  //   //console.log("$$$$$$$$$$$$$$$$$$$$$$$$", DoctorsTDSData);
  // }
  function HandleGenerate() {
    setLoading(true);
    // console.log("Loading inside HandleGenerate true");
    setSet(true);
  }
  useEffect(() => {
    let formData = new FormData();
    formData.append("Status", "Active");
    axios({
      method: "post",
      url: Domain + "/GetAllModalities.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        // //console.log("\n \t GetAllModalities:", response);
        if (response.data.success) {
          if (response.data.TotalCount > 0) setModalities(response.data.data);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  useEffect(() => {
    if (set) {
      // //console.log("Inside Effect");
      const StringId1 = Entity.replace(/ /g, "")
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .toLowerCase();
      //console.log("String ID:::::::::::::::::::::::::", StringId1);
      ///////////////////////////////////////
      //Get Doctor Data correcsponding to the stringid
      let formData = new FormData();
      formData.append("StringId", StringId1);
      axios({
        method: "post",
        url: Domain + "/GetDoctorByName.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          // //console.log("\n \t getDoctorByName:", response);
          if (response.data.success) {
            setEntityNotFound(false);
            // setDoctorDetails(response.data.data[0]);
            // setDocPan(response.data.data[0].Pan);
            //if Doctor details got from db, then get the body parts from db
            let formData = new FormData();
            formData.append("Id", response.data.data[0].Id);
            axios({
              method: "post",
              url: Domain + "/GetBodypartsByDoctorId.php",
              data: formData,
              config: { headers: { "Content-Type": "multipart/form-data" } },
            })
              .then(function (response) {
                //handle success
                // //console.log("\n \t GetBodypartsByDoctorId:", response);
                if (response.data.success) {
                  if (response.data.TotalCount > 0) {
                    setBodyParts(response.data.data);
                  }
                }
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });
          } else {
            setEntityNotFound(true);
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
  }, [set]);

  useEffect(() => {
    //console.log("generate");
    // //console.log("body___part:", BodyParts, "Modality__ss:", Modalities);
    if (BodyParts != null && Modalities != null) {
      //console.log("generate1");
      var Total = 0;

      if (data) {
        data.map((d) => {
          if (d.BodyPart.indexOf(",") > -1) {
            d.Price = 0;
            const splitData = d.BodyPart.split(",");
            // //console.log("Split Data::::", splitData);
            splitData.map((s) => {
              BodyParts.map((b) => {
                if (
                  b.StringId ==
                  s
                    .replace(/ /g, "")
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .toLowerCase()
                ) {
                  // //console.log("Price of :", b.BodyPart, ": ", b.Price);
                  d.Price = parseInt(d.Price) + parseInt(b.Price);
                }
              });
            });
          }
          // //console.log("###########3", d.BodyPart);
          BodyParts.map((b) => {
            if (
              b.StringId ==
              d.BodyPart.replace(/ /g, "")
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .toLowerCase()
            ) {
              d.Price = parseInt(b.Price);
            }
          });
          if (d.DayNight == "Night") {
            Modalities.map((m) => {
              if (m.ModalityName == d.Modality) {
                // console.log(
                //   "Price of ",
                //   m.ModalityName,
                //   " is:",
                //   m.DoctorNightCharge
                // );
                d.Price = parseInt(d.Price) + parseInt(m.DoctorNightCharge);
              }
            });
          }
          Total = Total + d.Price;
        });

        setData(data);
        setLoading(false);
        setTotal(Total);
        //console.log("Data After Price updation:::::", data);
      }
    }
  }, [BodyParts]);
  useEffect(() => {
    if (loaded) {
      HandleGenerate();
    }
  }, [loaded]);

  return (
    <>
      {EntityNotFound ? <Danger>Doctor {Entity} Not found</Danger> : <></>}
      {Loading ? (
        <>
          <LinearIndeterminate />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
Events.propTypes = {
  Entity: PropTypes.any,
  data: PropTypes.any,
  setData: PropTypes.any,
};
