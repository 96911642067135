import React from "react";
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Domain } from "Domain";
// import { makeStyles } from "@material-ui/core/styles";
// import ConfirmationNumberSharpIcon from "@material-ui/icons/ConfirmationNumberSharp";
// import Update from "@material-ui/icons/Update";
// import GridItem from "components/Grid/GridItem.js";
// import GridContainer from "components/Grid/GridContainer.js";
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
// import CardFooter from "components/Card/CardFooter.js";
// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

// const useStyles = makeStyles(styles);

export default function Dashboard() {
  // const classes = useStyles();
  // const [Raised, setRaised] = useState(0);
  // const [Assigned, setAssigned] = useState(0);
  // const [Working, setWorking] = useState(0);
  // const [Completed, setCompleted] = useState(0);
  // const [Pending, setPending] = useState(0);
  //To get Raised Tickets list from database
  // useEffect(() => {
  //   let formData = new FormData();

  //   formData.append("Status", "Raised");
  //   axios({
  //     method: "post",
  //     url: Domain + "/GetTicketsByStatus.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       if (response.data.success) {
  //         setRaised(response.data.TotalCount);
  //       }
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log(response);
  //     });
  // }, []);

  // //To get Assigned Tickets list from database
  // useEffect(() => {
  //   let formData = new FormData();

  //   formData.append("Status", "Assigned");
  //   axios({
  //     method: "post",
  //     url: Domain + "/GetTicketsByStatus.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       if (response.data.success) {
  //         setAssigned(response.data.TotalCount);
  //       }
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log(response);
  //     });
  // }, []);

  // //To get Working Tickets list from database
  // useEffect(() => {
  //   let formData = new FormData();

  //   formData.append("Status", "Working");
  //   axios({
  //     method: "post",
  //     url: Domain + "/GetTicketsByStatus.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       if (response.data.success) {
  //         setWorking(response.data.TotalCount);
  //       }
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log(response);
  //     });
  // }, []);

  // //To get Completed Tickets list from database
  // useEffect(() => {
  //   let formData = new FormData();

  //   formData.append("Status", "Completed");
  //   axios({
  //     method: "post",
  //     url: Domain + "/GetTicketsByStatus.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       if (response.data.success) {
  //         setCompleted(response.data.TotalCount);
  //       }
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log(response);
  //     });
  // }, []);
  // //To get Pending Tickets list from database
  // useEffect(() => {
  //   let formData = new FormData();

  //   formData.append("Status", "Pending");
  //   axios({
  //     method: "post",
  //     url: Domain + "/GetTicketsByStatus.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       if (response.data.success) {
  //         setPending(response.data.TotalCount);
  //       }
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log(response);
  //     });
  // }, []);
  return (
    <></>
    // <div>
    //   <GridContainer>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="warning" stats icon>
    //           <CardIcon color="warning">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Recently Raised Tickets</b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Raised}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="primary" stats icon>
    //           <CardIcon color="primary">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Assigned Tickets Count</b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Assigned}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="info" stats icon>
    //           <CardIcon color="info">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Working Tickets Count.. </b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Working}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="success" stats icon>
    //           <CardIcon color="success">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Completed Ticket Count</b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Completed}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="danger" stats icon>
    //           <CardIcon color="danger">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Pending Ticket Count</b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Pending}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //   </GridContainer>
    // </div>
  );
}
