import React, { useEffect, useState } from "react";

// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// core components
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardBody from "components/Card/CardBody.js";
import PropTypes from "prop-types";
import Table from "components/Table/Table1.js";
import Button from "components/CustomButtons/Button.js";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
// import Button from "components/CustomButtons/Button.js";
import { Domain } from "Domain";
import axios from "axios";
import LinearIndeterminate from "components/Loader/LinearLoader";
// import CardFooter from "components/Card/CardFooter";
import Danger from "components/Typography/Danger";

export default function Events({
  JsonData,
  Doctors,
  // Entity,
  // setData,
  // setTotal,
  // loaded,
  // setDocPan,
}) {
  // const classes = useStyles();
  // const [DoctorDetails, setDoctorDetails] = useState([]);
  const [BodyParts, setBodyParts] = useState(null);
  const [Modalities, setModalities] = useState(null);
  // const [set, setSet] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [EntityNotFound, setEntityNotFound] = useState(false);
  const [TDSDataArray, setArray] = useState([]);
  const [Entity, setEntity] = useState("");
  console.log("EntityTemp:", Entity);
  console.log("BodyParts", BodyParts);
  console.log("Doctors:", Doctors);
  function HandleClick() {
    Doctors.map((d) => {
      console.log("Name:::", d.Name);
      let data = JsonData.filter((l) => {
        return l.Doctor.match(d.Name);
      });
      console.log("data:", data);

      setEntity(d.Name);
      setLoading(true);
      // //console.log("Inside Effect");
      const StringId1 = d.Name.replace(/ /g, "")
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .toLowerCase();
      //console.log("String ID:::::::::::::::::::::::::", StringId1);
      ///////////////////////////////////////
      //Get Doctor Data correcsponding to the stringid
      let formData = new FormData();
      formData.append("StringId", StringId1);
      console.log("StringId1:::::", StringId1, ";");
      axios({
        method: "post",
        url: Domain + "/GetDoctorByName.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("\n \t getDoctorByName:", response);
          if (response.data.success) {
            setEntityNotFound(false);
            // setDoctorDetails(response.data.data[0]);
            // setDocPan(response.data.data[0].Pan);
            //if Doctor details got from db, then get the body parts from db
            let formData = new FormData();
            console.log("GetDoctorByName:", response.data);
            formData.append("Id", response.data.data[0].Id);
            axios({
              method: "post",
              url: Domain + "/GetBodypartsByDoctorId.php",
              data: formData,
              config: { headers: { "Content-Type": "multipart/form-data" } },
            })
              .then(function (response1) {
                //handle success
                // //console.log("\n \t GetBodypartsByDoctorId:", response);
                if (response1.data.success) {
                  if (response1.data.TotalCount > 0) {
                    setBodyParts(response1.data.data);
                    if (response1.data.data != null && Modalities != null) {
                      console.log("generate1");
                      var Total = 0;

                      if (data) {
                        data.map((a) => {
                          console.log("Inside data");
                          if (a.BodyPart.indexOf(",") > -1) {
                            a.Price = 0;
                            const splitData = a.BodyPart.split(",");
                            console.log("Split Data::::", splitData);
                            splitData.map((s) => {
                              response1.data.data.map((b) => {
                                if (
                                  b.StringId ==
                                  s
                                    .replace(/ /g, "")
                                    .replace(/[^a-zA-Z0-9 ]/g, "")
                                    .toLowerCase()
                                ) {
                                  console.log(
                                    "Price of :",
                                    b.BodyPart,
                                    ": ",
                                    b.Price
                                  );
                                  a.Price =
                                    parseInt(a.Price) + parseInt(b.Price);
                                }
                              });
                            });
                          }
                          console.log("###########3", a.BodyPart);
                          response1.data.data.map((b) => {
                            if (
                              b.StringId ==
                              a.BodyPart.replace(/ /g, "")
                                .replace(/[^a-zA-Z0-9 ]/g, "")
                                .toLowerCase()
                            ) {
                              a.Price = parseInt(b.Price);
                            }
                          });
                          if (a.DayNight == "Night") {
                            Modalities.map((m) => {
                              if (m.ModalityName == a.Modality) {
                                // console.log(
                                //   "Price of ",
                                //   m.ModalityName,
                                //   " is:",
                                //   m.DoctorNightCharge
                                // );
                                a.Price =
                                  parseInt(a.Price) +
                                  parseInt(m.DoctorNightCharge);
                              }
                            });
                          }
                          Total = Total + a.Price;
                        });
                        const DoctorsTDSData = {
                          Name: d.Name,
                          TotalAmount: Total,
                          TDS: parseFloat(Total) * 0.1,
                          Amount: Total - parseFloat(Total) * 0.1,
                          Pan: response.data.data[0].Pan,
                          StringId: d.Name.replace(/ /g, "")
                            .replace(/[^a-zA-Z0-9 ]/g, "")
                            .toLowerCase(),
                        };
                        console.log("Entity::", d.Name);
                        if (!TDSDataArray.includes(d.Name))
                          setArray((oldArray) => [...oldArray, DoctorsTDSData]);
                        // setData(data);
                        setLoading(false);
                        // setTotal(Total);
                        console.log("Data After Price updation:::::", Total);
                      }
                    }
                  }
                }
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });
          } else {
            setEntityNotFound(true);
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    });
  }

  const TDSData = TDSDataArray.map((d, key) => ({
    Id: key + 1,
    Name: d.Name,
    Pan: d.Pan,
    TotalAmount: "₹ " + d.TotalAmount + ".00",
    TDS: "₹ " + d.TDS + ".00",
    Amount: "₹ " + d.Amount + ".00",
  }));
  // function HandleGenerate() {

  //   // console.log("Loading inside HandleGenerate true");

  // }
  useEffect(() => {
    let formData = new FormData();
    formData.append("Status", "Active");
    axios({
      method: "post",
      url: Domain + "/GetAllModalities.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        // //console.log("\n \t GetAllModalities:", response);
        if (response.data.success) {
          if (response.data.TotalCount > 0) setModalities(response.data.data);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  // useEffect(() => {
  //   //console.log("generate");
  //   // //console.log("body___part:", BodyParts, "Modality__ss:", Modalities);

  // }, [BodyParts]);
  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }
  async function SaveExcel() {
    if (TDSData) {
      let header = [
        "Sl.No",
        "Name of the Deductee",
        "Pan of the Deductee",
        "Amount",
        "TDS Deducted",
        "Amount Credited",
      ];

      XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const sheetData = getSheetData(TDSData, header);
        const totalColumns = sheetData[0].length;

        sheet1.cell("A1").value(sheetData);
        sheet1.column("A").width(10);
        sheet1.column("B").width(25);
        sheet1.column("C").width(25);
        sheet1.column("D").width(25);
        sheet1.column("E").width(25);
        sheet1.column("F").width(25);

        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        sheet1.range("A1:" + endColumn + "1").style("fill", "d3d3d3");

        range.style("border", false);
        return workbook.outputAsync().then((res) => {
          saveAs(res, "TDS_data_" + Date() + ".xlsx");
        });
      });
    }
  }
  return (
    <>
      {EntityNotFound ? <Danger>Error!</Danger> : <></>}
      {Loading ? (
        <>
          <LinearIndeterminate />
        </>
      ) : (
        <>
          <Button color="info" onClick={HandleClick}>
            Generate TDS
          </Button>
          <Button color="success" onClick={SaveExcel}>
            Download Excel
          </Button>
          <Table
            tableHeaderColor="info"
            tableHead={[
              "Sl.No",
              "Name of the Deductee",
              "Pan of the Deductee",
              "Amount",
              "TDS Deducted",
              "Amount Credited",
            ]}
            tableData={TDSData}
            loading={false}
            noAction={true}
          />
        </>
      )}
    </>
  );
}
Events.propTypes = {
  Entity: PropTypes.any,
  data: PropTypes.any,
  // setData: PropTypes.any,
};
