import React, { useEffect, useState } from "react";
import { Domain } from "Domain";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import axios from "axios";
import Danger from "components/Typography/Danger";
import LoadingOverlay from "react-loading-overlay";
// import ImageUpload from "components/CustomUpload/ImageUpload.js";

// import AttachFile from "@material-ui/icons/AttachFile";
// import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";
import EmptyTable from "components/EmptyTable";
// import Pagination from "components/Pagination/Pagination";
// import { data } from "./data.json";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Modalities() {
  const classes = useStyles();
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [edit, setEdit] = useState([]);
  // const [deletee, setDelete] = useState([]);
  const [Modality, setModality] = useState([]);
  // const [files, setFiles] = useState(null);
  const [validated, setValidated] = useState(true);
  // const [uploaded, setUploaded] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [deleting, setDeleting] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [saving, setSaving] = useState(false);

  const ModalityData = Modality.map((d) => ({
    Id: d.Id,
    ModalityName: d.ModalityName,
    DoctorNightCharge: d.DoctorNightCharge,
    ClientNightCharge: d.ClientNightCharge,
    Status: d.Status,
  }));

  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  //Deleted Notification Trigger
  const showDeletedNotification = () => {
    if (!deleted) {
      setDeleted(true);
      setTimeout(function () {
        setDeleted(false);
      }, 3000);
    }
  };
  //Form Data
  const [data, setData] = useState({
    Id: 0,
    ModalityName: "",
    ClientNightCharge: "",
    DoctorNightCharge: "",
    Status: "Active",
  });

  //PassData for getAll API
  // let passData = {
  //   PageIndex: 0,
  //   PageSize: 10,
  // };

  //PaddData for Delete a Row
  // let passDelete = {
  //   Id: deletee,
  //   DeletedBy: 2,
  // };
  //PassData for getting event by id
  // let passEdit = {
  //   Id: edit,
  // };
  //Function to handle Data input
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  }
  function HandleClear() {
    setData({
      Id: 0,
      ModalityName: "",
      ClientNightCharge: "",
      DoctorNightCharge: "",
      Status: "Created",
    });
    setEdit("");
  }
  //Function for Validating fields
  function ValidateFields() {
    if (data.ModalityName == "") {
      return false;
    } else if (data.ClientNightCharge == "") {
      return false;
    } else if (data.DoctorNightCharge == "") {
      return false;
    } else return true;
  }
  //function to upload
  // function UploadImage() {
  //   if (files != null) {
  //     setValidated(true);
  //     setSaving(true);
  //     let form_data = new FormData();
  //     form_data.append("File", files[0]);
  //     let url = Domain + "/api/Uploads/File/";
  //     axios
  //       .post(url, form_data, {
  //         headers: {
  //           "content-type": "multipart/form-data",
  //         },
  //       })
  //       .then((res) => {
  //         if (res.data.Success) {
  //           data.Image = res.data.Data[0];
  //           setUploaded(true);
  //           HandleSave();
  //         } else {
  //           setUploaded(false);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setUploaded(false);
  //       });
  //   } else if (data.Image != "") HandleSave();
  //   else setValidated(false);
  // }

  //Function to save Data
  function HandleSave() {
    if (ValidateFields()) {
      setValidated(true);

      let formData = new FormData();
      if (edit.length != 0) {
        formData.append("Id", edit);
      } else {
        formData.append("Id", 0);
      }
      formData.append("ModalityName", data.ModalityName);
      formData.append("DocNightchrg", data.DoctorNightCharge);
      formData.append("ClNightChrg", data.ClientNightCharge);
      formData.append("Status", data.Status);

      axios({
        method: "post",
        url: Domain + "/SaveModality.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("Modality saved:", response.data);
          if (response.data.TotalCount != 0) {
            HandleClear();
            setEmpty(false);
            showSavedNotification();
            setSaving(false);
          } else {
            setEmpty(true);
          }
        })
        .catch(function (response) {
          //handle error
          showDeletedNotification();
          console.log(response);
        });
      ////////////////////////////////////
      // fetch(Domain + "/api/Admin/CreateClubs/", {
      //   method: "POST",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(data),
      // })
      //   .then((response) => response.json())

      //   .then((json) => {
      //     if (json.Success) {
      //       setData({
      //         Id: 0,
      //         Name: "",
      //         Link: "",
      //         StaffId: "",
      //         Status: "Created",
      //         Image: "",
      //         Description: "",
      //       });
      //       setEmpty(false);
      //       showSavedNotification();
      //       setSaving(false);
      //     } else {
      //       console.log("Error in insertion");
      //     }
      //   });
    } else {
      setValidated(false);
    }
  }

  // const [TotalCount, setTotalCount] = useState();
  // const [pageIndex, setPageIndex] = useState(0);
  // const [pagination, setPagination] = useState(false);

  // useEffect(() => {
  //   let passData = {
  //     PageIndex: pageIndex,
  //     PageSize: 10,
  //   };
  //   fetch(Domain + "/api/Admin/GetAllClubs/", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(passData),
  //   })
  //     .then((response) => response.json())

  //     .then((json) => {
  //       setModality(json.Data);
  //       if (json.TotalCount > 10) {
  //         // console.log("pages", Math.ceil(json.TotalCount / 10));
  //         setTotalCount(Math.ceil(json.TotalCount / 10));

  //         setPagination(true);
  //       }
  //     });
  // }, [pageIndex]);

  useEffect(() => {
    //API call for get latest 10 elements
    let formData = new FormData();
    formData.append("Status", "Active");
    axios({
      method: "post",
      url: Domain + "/GetAllModalities.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Modalities Response:", response.data);
        if (response.data.TotalCount != 0) {
          setModality(response.data.data);
          setLoading(false);
        } else {
          setEmpty(true);
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
    // fetch(Domain + "/api/Admin/GetAllClubs/", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(passData),
    // })
    //   .then((response) => response.json())

    //   .then((json) => {
    //     setModality(json.Data);
    //     if (json.Data.length == 0) setEmpty(true);
    //     setLoading(false);
    //   });

    //API call for Delete a row
    // if (deletee.length != 0) {
    //   setDeleting(true);
    //   fetch(Domain + "/api/Admin/DeleteClubs/", {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(passDelete),
    //   })
    //     .then((response) => response.json())

    //     .then((json) => {
    //       if (json.Success) {
    //         setDelete([]);
    //         showDeletedNotification();
    //         setDeleting(false);
    //       }
    //     });
    // }

    //API call to get event By ID to edit a row
  }, [saved]);

  useEffect(() => {
    console.log("Edit Clicked:", edit);
    if (edit != "") setData(ModalityData[edit - 1]);
  }, [edit]);
  return (
    <>
      <Snackbar
        place="bc"
        color="success"
        icon={AddAlert}
        message="Modality Saved Successfully"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="bc"
        color="danger"
        icon={AddAlert}
        message="Modality Deleted Successfully"
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />
      <LoadingOverlay active={saving} spinner text="Saving Please Wait..">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <form>
                <CardHeader color="info">
                  <h4 className={classes.cardTitleWhite}>Add New Modality</h4>
                  <p className={classes.cardCategoryWhite}>
                    Enter the Modality details below and hit Save
                  </p>
                </CardHeader>

                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        onChange={(e) => HandleData(e)}
                        value={data.ModalityName}
                        labelText="Modality Name"
                        id="ModalityName"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        onChange={(e) => HandleData(e)}
                        value={data.DoctorNightCharge}
                        labelText="Doctor's Night charge"
                        id="DoctorNightCharge"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        onChange={(e) => HandleData(e)}
                        value={data.ClientNightCharge}
                        labelText="Client's Night charge"
                        id="ClientNightCharge"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={5} md={5}>
                      {" "}
                      {validated ? (
                        <></>
                      ) : (
                        <Danger>Please enter all the details to save</Danger>
                      )}
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button onClick={HandleClear} color="defualt">
                    Clear
                  </Button>
                  <Button onClick={HandleSave} color="info">
                    Save
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>List Of All Modalities</h4>
              <p className={classes.cardCategoryWhite}>
                All Modalities are listed below, you can delete or edit them.
              </p>
            </CardHeader>
            <CardBody>
              <LoadingOverlay active={false} spinner text="Please Wait..">
                {empty ? (
                  <EmptyTable />
                ) : (
                  <Table
                    tableHeaderColor="info"
                    tableHead={[
                      "ID",
                      "Modality Name",
                      "Docotr Night Charge",
                      "Client Night Charge",
                      "Status",
                      "Actions",
                    ]}
                    tableData={ModalityData}
                    setEdit={setEdit}
                    loading={loading}
                  />
                )}
              </LoadingOverlay>
            </CardBody>
          </Card>
          {/* {pagination ? (
            <Pagination
              TotalCount={TotalCount}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
              className={
                classes.textCenter + " " + classes.justifyContentCenter
              }
              pages={[
                { text: "Previous" },
                { active: true, text: "1" },

                { text: "Next" },
              ]}
              color="info"
            />
          ) : (
            <></>
          )} */}
        </GridItem>
      </GridContainer>
    </>
  );
}
