import React, { useEffect, useState } from "react";

// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// core components

import PropTypes from "prop-types";

import { Domain } from "Domain";
import axios from "axios";
import LinearIndeterminate from "components/Loader/LinearLoader";
import Danger from "components/Typography/Danger";

export default function Events({ data, Entity, setData, setTotal, loaded }) {
  // const classes = useStyles();
  const [ClientDetails, setClientDetails] = useState([]);
  const [BodyParts, setBodyParts] = useState(null);
  const [Modalities, setModalities] = useState(null);
  const [set, setSet] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [EntityNotFound, setEntityNotFound] = useState(false);
  console.log("\n \t ClientDetails:", ClientDetails);
  console.log("\n \t Modalities:", Modalities);
  console.log("\n \t BodyParts:", BodyParts);

  function HandleGenerate() {
    setLoading(true);

    setSet(true);
  }
  useEffect(() => {
    let formData = new FormData();
    formData.append("Status", "Active");
    axios({
      method: "post",
      url: Domain + "/GetAllModalities.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        // console.log("\n \t GetAllModalities:", response);
        if (response.data.success) {
          if (response.data.TotalCount > 0) setModalities(response.data.data);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  useEffect(() => {
    if (set) {
      console.log("Inside Effect");
      const StringId1 = Entity.replace(/ /g, "")
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .toLowerCase();
      console.log("String ID:::::::::::::::::::::::::", StringId1);
      ///////////////////////////////////////
      //Get Client Data correcsponding to the stringid
      let formData = new FormData();
      formData.append("StringId", StringId1);
      axios({
        method: "post",
        url: Domain + "/GetClientByName.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          // console.log("\n \t getClientByName:", response);
          if (response.data.success) {
            setEntityNotFound(false);

            setClientDetails(response.data.data[0]);
            //if Client details got from db, then get the body parts from db
            let formData = new FormData();
            formData.append("Id", response.data.data[0].Id);
            axios({
              method: "post",
              url: Domain + "/GetBodypartsByClientId.php",
              data: formData,
              config: { headers: { "Content-Type": "multipart/form-data" } },
            })
              .then(function (response) {
                //handle success
                console.log("\n \t GetBodypartsByClientId:", response);
                if (response.data.success) {
                  if (response.data.TotalCount > 0) {
                    setBodyParts(response.data.data);
                  }
                }
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });
          } else {
            setEntityNotFound(true);
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
  }, [set]);

  useEffect(() => {
    console.log("generate");
    console.log("body___part:", BodyParts, "Modality__ss:", Modalities);
    if (BodyParts != null && Modalities != null) {
      console.log("generate1");
      var Total = 0;
      if (data) {
        data.map((d) => {
          if (d.BodyPart.indexOf(",") > -1) {
            d.Price = 0;
            const splitData = d.BodyPart.split(",");
            console.log("Split Data::::", splitData);
            splitData.map((s) => {
              BodyParts.map((b) => {
                if (
                  b.StringId ==
                  s
                    .replace(/ /g, "")
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .toLowerCase()
                ) {
                  console.log("Price of :", b.BodyPart, ": ", b.Price);
                  d.Price = parseInt(d.Price) + parseInt(b.Price);
                }
              });
            });
          }
          console.log("###########3", d.BodyPart);

          BodyParts.map((b) => {
            if (
              b.StringId ==
              d.BodyPart.replace(/ /g, "")
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .toLowerCase()
            ) {
              d.Price = parseInt(b.Price);
            }
          });
          if (d.DayNight == "Night") {
            Modalities.map((m) => {
              if (m.ModalityName == d.Modality) {
                console.log(
                  "Price of ",
                  m.ModalityName,
                  " is:",
                  m.ClientNightCharge
                );
                d.Price = parseInt(d.Price) + parseInt(m.ClientNightCharge);
              }
            });
          }
          Total = Total + d.Price;
        });
        setData(data);
        setLoading(false);
        setTotal(Total);
        console.log("Data After Price updation:::::", data);
      }
    }
  }, [BodyParts]);
  useEffect(() => {
    if (loaded) {
      HandleGenerate();
    }
  }, [loaded]);
  return (
    <>
      {EntityNotFound ? <Danger>Client {Entity} Not found</Danger> : <></>}

      {Loading ? (
        <>
          <LinearIndeterminate />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
Events.propTypes = {
  Entity: PropTypes.any,
  data: PropTypes.any,
  setData: PropTypes.any,
};
