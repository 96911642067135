import React, { useEffect, useState } from "react";
import { Domain } from "Domain";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Table from "components/Table/Table2.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import axios from "axios";
import Danger from "components/Typography/Danger";
// import ImageUpload from "components/CustomUpload/ImageUpload.js";
import { saveAs } from "file-saver";

// import AttachFile from "@material-ui/icons/AttachFile";
// import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";
import EmptyTable from "components/EmptyTable";
import SingleSelect from "components/SingleSelect";
import LinearIndeterminate from "components/Loader/LinearLoader";
import LoadingOverlay from "react-loading-overlay";

import Dialog from "@mui/material/Dialog";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { Close } from "@material-ui/icons";
import Slide from "@mui/material/Slide";
import { Chip } from "@material-ui/core";
import * as xlsx from "xlsx";
import XlsxPopulate from "xlsx-populate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// import { data } from "./data.json";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryGrey: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgb(128,128,128)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "500",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardStyle: {
    padding: "10px",
    paddingRight: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function ClientsList() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [edit, setEdit] = useState([]);
  const [Clients, setClients] = useState([]);
  // const [files, setFiles] = useState(null);
  const [BodyPartvalidated, setBodyPartValidated] = useState(true);
  const [validated, setValidated] = useState(true);
  const [loading, setLoading] = useState(true);
  const [empty, setEmpty] = useState(false);
  const [AddStaff, setAddStaff] = useState(false);
  const [Open, setOpen] = useState(false);
  const [BodyParts, setBodyParts] = useState([]);
  const [ClientDetails, setClientDetails] = useState([]);
  const [BodyPartsLoading, setBodyPartsLoading] = useState(false);
  const [EditLoading, setEditLoading] = useState(false);
  const [openDiloge, setOpenDiloge] = React.useState(false);
  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  //Deleted Notification Trigger
  const showDeletedNotification = () => {
    if (!deleted) {
      setDeleted(true);
      setTimeout(function () {
        setDeleted(false);
      }, 3000);
    }
  };
  //Form Data
  const [data, setData] = React.useState({
    Id: 0,
    FullName: "",
  });
  const [bodypartData, setBodypartData] = React.useState({
    Id: 0,
    BodyPart: "",
    Price: "",
    Modality: "",
    ModalityId: "",
    ClientId: "",
    Status: "Active",
  });
  useEffect(() => {
    if (!AddBodyPart) {
      setBodypartData({
        Id: 0,
        BodyPart: "",
        Price: "",
        Modality: "",
        ModalityId: "",
        ClientId: "",
        Status: "Active",
      });
    }
  }, [AddBodyPart]);
  //Function to handle Data input
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }
  function HandleBodyData(e) {
    const newData = { ...bodypartData };
    newData[e.target.id] = e.target.value;
    setBodypartData(newData);
    console.log(newData);
  }
  function HandleClear() {
    setData({
      Id: 0,
      FullName: "",
    });
  }
  //Function for Validating fields
  function ValidateFields() {
    if (data.FullName == "") {
      return false;
    } else return true;
  }

  //To get Developer list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Status", "Active");
    axios({
      method: "post",
      url: Domain + "/GetAllClients.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Clients Response:", response.data);
        if (response.data.TotalCount != 0) {
          setClients(response.data.data);
          console.log("setClients ", response.data.data);
          setLoading(false);
        } else {
          setEmpty(true);
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, []);

  const ClientsData = Clients.map((d) => ({
    Id: d.Id,
    Name: d.Name,
  }));
  //Function for Validating fields
  function ValidateBodyPartFields() {
    if (bodypartData.BodyPart == "") {
      return false;
    } else if (ModalityValues.label == "") {
      return false;
    } else if (bodypartData.Price == "") {
      return false;
    } else return true;
  }
  function HandleBoyPartSave() {
    if (ValidateBodyPartFields()) {
      setBodyPartValidated(true);
      let formData = new FormData();
      formData.append("Id", bodypartData.Id);
      formData.append("BodyPart", bodypartData.BodyPart);
      formData.append("Price", bodypartData.Price);
      formData.append("Modality", ModalityValues.Label);
      formData.append("ModalityId", ModalityValues.Id);
      formData.append("ClientId", edit);
      formData.append(
        "StringId",
        bodypartData.BodyPart.replace(/ /g, "")
          .replace(/[^a-zA-Z0-9 ]/g, "")
          .toLowerCase()
      );
      axios({
        method: "post",
        url: Domain + "/saveBodyPartsClient.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          if (response.data.success) {
            setBodypartData({
              Id: 0,
              BodyPart: "",
              Price: "",
              Modality: "",
              ModalityId: "",
              ClientId: "",
              Status: "Active",
            });
            setAddStaff(false);
            HandleClose();
            setEmpty(false);
            showSavedNotification();
            setBodyPartEdit("");
            console.log("saved");
          } else {
            showDeletedNotification;
            console.log("failed");
          }
        })
        .catch(function (response) {
          //handle error
          setDeleted(true);
          console.log(response);
        });
    } else {
      setBodyPartValidated(false);
    }
  }
  //Function to save Data
  function HandleSave() {
    if (ValidateFields()) {
      setValidated(true);
      let formData = new FormData();
      formData.append("Id", data.Id);
      formData.append("FullName", data.FullName);
      formData.append(
        "StringId",
        data.FullName.replace(/ /g, "")
          .replace(/[^a-zA-Z0-9 ]/g, "")
          .toLowerCase()
      );
      axios({
        method: "post",
        url: Domain + "/SaveClient.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          if (response.data.success) {
            setData({
              Id: 0,
              FullName: "",
            });
            //call the api for insert dummy price dumb
            /////////////////////////////////////////

            let formData = new FormData();
            formData.append("Id", response.data.data.Id);
            axios({
              method: "post",
              url: Domain + "/SaveClientDefaultData.php",
              data: formData,
              config: { headers: { "Content-Type": "multipart/form-data" } },
            })
              .then(function (response) {
                //handle success
                console.log(" defualt BodyParts Added", response);
                if (response.data.success) {
                  setAddStaff(false);
                  setEmpty(false);
                  showSavedNotification();
                  //console.log("saved");
                } else {
                  showDeletedNotification;
                  //console.log("failed");
                }
              })
              .catch(function (response) {
                //handle error
                setDeleted(true);
                console.log(response);
              });

            /////////////////////////////////////////
          } else {
            showDeletedNotification;
            console.log("failed");
          }
        })
        .catch(function (response) {
          //handle error
          setDeleted(true);
          console.log(response);
        });
    } else {
      setValidated(false);
    }
  }
  useEffect(() => {
    console.log("clicked: ", edit);
    if (edit != "") {
      setOpen(true);
      //Get Data correcsponding to the id
      let formData = new FormData();

      formData.append("Id", edit);

      axios({
        method: "post",
        url: Domain + "/GetClientById.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("GetClientById:", response);
          if (response.data.success) {
            setClientDetails(response.data.data[0]);
            //if Client details got from db, then get the body parts from db
            let formData = new FormData();
            formData.append("Id", edit);
            axios({
              method: "post",
              url: Domain + "/GetBodypartsByClientId.php",
              data: formData,
              config: { headers: { "Content-Type": "multipart/form-data" } },
            })
              .then(function (response) {
                //handle success
                console.log("GetBodypartsByClientId:", response);
                if (response.data.success) {
                  if (response.data.TotalCount > 0)
                    setBodyParts(response.data.data);
                }
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
  }, [edit, openDiloge]);
  function HandleBack() {
    setOpen(false);
    setEdit("");
    setClientDetails([]);
  }
  useEffect(() => {}, []);
  const bodyParts = BodyParts.map((d) => ({
    Id: d.Id,
    Name: d.BodyPart,
    Modality: (
      <Chip
        onClick={() => HandleModalityFilter(d.Modality, edit)}
        label={d.Modality}
      />
    ),

    Price: d.Price,
    Status: d.Status,
  }));
  function HandleModalityFilter(Modality, Id) {
    let formData = new FormData();

    formData.append("Id", Id);
    formData.append("Modality", Modality);
    axios({
      method: "post",
      url: Domain + "/GetBodypartsByClientIdAndModality.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("GetBodypartsByClientIdAndModality", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setBodyParts(response.data.data);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }
  // const [selectedValue, setSelectedValue] = useState({ value: "", label: "" });
  const ModalityList = [
    { value: 1, label: "CT" },
    { value: 2, label: "MR" },
    { value: 3, label: "DX" },
  ];
  const [ModalityValues, SetModalityValues] = React.useState({
    Id: null,
    label: "",
  });
  console.log("ModalityValues:", ModalityValues);
  const [AddBodyPart, setAddBodyPart] = useState(false);
  const [BodyPartEdit, setBodyPartEdit] = useState("");
  const [selected, setSelected] = useState("");

  useEffect(() => {
    setBodyPartsLoading(true);
    if (BodyParts != "") {
      setBodyPartsLoading(false);
      if (BodyPartEdit != "") {
        setEditLoading(true);

        ///////////////////////////////////////
        let formData = new FormData();
        formData.append("Id", BodyPartEdit);
        axios({
          method: "post",
          url: Domain + "/GetBodypartsByIdClient.php",
          data: formData,
          config: { headers: { "Content-Type": "multipart/form-data" } },
        })
          .then(function (response) {
            //handle success
            console.log("GetBodypartsByIdClient:", response);
            if (response.data.success) {
              if (response.data.TotalCount > 0) {
                setBodypartData(response.data.data[0]);

                // setAddBodyPart(true);
                setEditLoading(false);
                setOpenDiloge(true);
                setSelected({
                  value: response.data.data[0].ModalityId,
                  label: response.data.data[0].Modality,
                });
                SetModalityValues({
                  Id: response.data.data[0].ModalityId,
                  Label: response.data.data[0].Modality,
                });

                // setSelectedValue({
                //   value: response.data.data[0].ModalityId,
                //   label: response.data.data[0].Modality,
                // });
              } else {
                setEditLoading(false);
              }
            } else {
              setEditLoading(false);
            }
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      } else {
        setAddBodyPart(false);
      }
    } else {
      console.log("BodyPart Error");
    }
  }, [BodyPartEdit, BodyParts]);
  function HandleClearFliter() {
    let formData = new FormData();

    formData.append("Id", edit);

    axios({
      method: "post",
      url: Domain + "/GetClientById.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("GetClientById:", response);
        if (response.data.success) {
          setClientDetails(response.data.data[0]);
          //if Client details got from db, then get the body parts from db
          let formData = new FormData();
          formData.append("Id", edit);
          axios({
            method: "post",
            url: Domain + "/GetBodypartsByClientId.php",
            data: formData,
            config: { headers: { "Content-Type": "multipart/form-data" } },
          })
            .then(function (response) {
              //handle success
              console.log("GetBodypartsByClientId:", response);
              if (response.data.success) {
                if (response.data.TotalCount > 0)
                  setBodyParts(response.data.data);
              }
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }
  // console.log("bodypartData:", bodypartData);
  // console.log("BodyPartEdit:", BodyPartEdit);
  // console.log("BodyParts[BodyPartEdit]:", BodyParts[BodyPartEdit - 1]);
  function HandleClose() {
    setAddBodyPart(false);
    setBodyPartEdit("");
    setBodypartData({
      Id: 0,
      BodyPart: "",
      Price: "",
      Modality: "",
      ModalityId: "",
      ClientId: "",
      Status: "Active",
    });
    setOpenDiloge(false);
  }
  // const handleClickOpen = () => {
  //   setOpenDiloge(true);
  // };

  const bodyPartSelect = BodyParts.map((d) => ({
    value: d.Id,
    label: d.BodyPart,
  }));
  const [SelectedBodyPart, SetSelectedBodyPart] = useState("");
  console.log("SelectedBodyPart:", SelectedBodyPart);
  useEffect(() => {
    setBodyPartEdit(SelectedBodyPart.Id);
  }, [SelectedBodyPart.Id]);

  ///Upload excel
  const [jsontoInsert, setJsontoInsert] = useState([]);
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        setJsontoInsert(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }
  async function saveAsExcelClient() {
    if (BodyParts) {
      let header = [
        "Id",
        "BodyPart",
        "Price",
        "Modality",
        "ModalityId",
        "Status",
        "ClientId",
      ];

      XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const sheetData = getSheetData(BodyParts, header);
        const totalColumns = sheetData[0].length;

        sheet1.cell("A1").value(sheetData);
        sheet1.column("A").width(10);
        sheet1.column("B").width(25);
        sheet1.column("C").width(25);
        sheet1.column("D").width(25);
        sheet1.column("E").width(25);
        sheet1.column("F").width(25);
        sheet1.column("G").width(25);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        sheet1.range("A1:" + endColumn + "1").style("fill", "d3d3d3");
        range.style("border", false);
        return workbook.outputAsync().then((res) => {
          saveAs(res, "bodyparts_client.xlsx");
        });
      });
    }
  }
  function HandleUploadBulkData() {
    console.log("clicked", jsontoInsert);
    if (jsontoInsert != []) {
      let formData = new FormData();
      formData.append("Id", edit);
      axios({
        method: "post",
        url: Domain + "/DeleteBodyPartsByClientId.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("DeleteBodyParts Response:", response);
          if (response.data.success) {
            axios({
              method: "post",
              url: Domain + "/SaveBodyPartsFromExcel.php",
              data: JSON.stringify(jsontoInsert),
              config: { headers: { "Content-Type": "multipart/form-data" } },
            })
              .then(function (response) {
                //handle success
                console.log("ExcelUpload Response:", response);
                if (response.data.TotalCount != 0) {
                  //success
                  alert("Upload Complete");
                }
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    } else {
      alert("Select excel file to upload!");
    }
  }
  return (
    <>
      <Snackbar
        place="bc"
        color="success"
        icon={AddAlert}
        message="Client Added Successfully"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="bc"
        color="danger"
        icon={AddAlert}
        message="Failed! Try again"
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />
      {Open ? (
        <>
          <Button onClick={HandleBack}>Back</Button> <br />
          <Card>
            <CardHeader color="info">
              <h3>
                {ClientDetails.Name}{" "}
                <Button
                  color="success"
                  round
                  onClick={() => setAddBodyPart(true)}
                >
                  Add +
                </Button>
              </h3>
              <b>Total BodyParts: {bodyParts.length} </b>{" "}
            </CardHeader>
            <CardBody>
              {AddBodyPart ? (
                <Card>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          onChange={(e) => HandleBodyData(e)}
                          value={bodypartData.BodyPart}
                          labelText="Body Part"
                          id="BodyPart"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          onChange={(e) => HandleBodyData(e)}
                          value={bodypartData.Price}
                          labelText="Price"
                          id="Price"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <SingleSelect
                          noOptionsMessage="No modalities"
                          placeholder="Select Modality"
                          Options={ModalityList}
                          setValue={SetModalityValues}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          // selectedValue={selectedValue}
                        />
                      </GridItem>
                      {BodyPartvalidated ? (
                        <></>
                      ) : (
                        <Danger>Input all the details</Danger>
                      )}
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Button onClick={HandleClose} color="danger">
                      Cancel
                    </Button>

                    <Button onClick={HandleBoyPartSave} color="info">
                      Save
                    </Button>
                  </CardFooter>
                </Card>
              ) : (
                <></>
              )}
              <Card>
                <CardFooter>
                  <form>
                    <label htmlFor="upload">Upload File</label>
                    <input
                      type="file"
                      name="upload"
                      id="upload"
                      onChange={readUploadFile}
                    />
                  </form>
                  <Button onClick={saveAsExcelClient} color="success">
                    Download Excel
                  </Button>
                  <Button onClick={HandleUploadBulkData} color="success">
                    Upload
                  </Button>
                </CardFooter>
              </Card>
              <CardFooter>
                <GridItem xs={12} sm={12} md={4}>
                  <Button onClick={HandleClearFliter}>Clear filter</Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <SingleSelect
                    noOptionsMessage="No bodyParts"
                    placeholder="Search BodyPart"
                    Options={bodyPartSelect}
                    setValue={SetSelectedBodyPart}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>{" "}
              </CardFooter>
              {BodyPartsLoading ? (
                <>
                  <LinearIndeterminate />
                </>
              ) : (
                <>
                  <LoadingOverlay
                    active={EditLoading}
                    spinner
                    text="Please Wait.."
                  >
                    <Table
                      tableHeaderColor="info"
                      tableHead={[
                        "Id",
                        "BodyPart",
                        "Modality",
                        "Price",
                        "Status",
                        "Action",
                      ]}
                      tableData={bodyParts}
                      loading={false}
                      noAction={false}
                      setEdit={setBodyPartEdit}
                    />
                  </LoadingOverlay>

                  <Dialog
                    fullScreen
                    open={openDiloge}
                    onClose={HandleClose}
                    TransitionComponent={Transition}
                  >
                    <AppBar sx={{ position: "relative" }}>
                      <Toolbar>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={HandleClose}
                          aria-label="close"
                        >
                          <Close />
                        </IconButton>
                        <Typography
                          sx={{ ml: 2, flex: 1 }}
                          variant="h6"
                          component="div"
                        >
                          Edit Client&apos;s Body Part Details
                        </Typography>
                      </Toolbar>
                    </AppBar>
                    <Card>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                              onChange={(e) => HandleBodyData(e)}
                              value={bodypartData.BodyPart}
                              labelText="Body Part"
                              id="BodyPart"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                              onChange={(e) => HandleBodyData(e)}
                              value={bodypartData.Price}
                              labelText="Price"
                              id="Price"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <SingleSelect
                              selectedVal={selected}
                              noOptionsMessage="No modalities"
                              placeholder="Select Modality"
                              Options={ModalityList}
                              setValue={SetModalityValues}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          {BodyPartvalidated ? (
                            <></>
                          ) : (
                            <Danger>Input all the details</Danger>
                          )}
                        </GridContainer>
                      </CardBody>
                      <CardFooter>
                        <Button onClick={HandleClose} color="danger">
                          Cancel
                        </Button>

                        <Button onClick={HandleBoyPartSave} color="info">
                          Save
                        </Button>
                      </CardFooter>
                    </Card>
                  </Dialog>
                </>
              )}
            </CardBody>
          </Card>
        </>
      ) : (
        <>
          {AddStaff ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <form>
                    <CardHeader color="info">
                      <h4 className={classes.cardTitleWhite}>
                        Client&apos;s Details
                      </h4>
                      <p className={classes.cardCategoryWhite}>
                        Enter the Details
                      </p>
                    </CardHeader>

                    <CardBody>
                      <Card className={classes.cardStyle}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                              onChange={(e) => HandleData(e)}
                              value={data.FullName}
                              labelText="Name"
                              id="FullName"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </Card>

                      <GridContainer>
                        <GridItem xs={12} sm={5} md={4}>
                          {" "}
                          {validated ? (
                            <></>
                          ) : (
                            <Danger>
                              Please enter Client&apos;s Name to save
                            </Danger>
                          )}
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                    <CardFooter>
                      <Button onClick={() => setAddStaff(false)} color="danger">
                        Cancel
                      </Button>

                      <Button onClick={HandleClear}>Clear</Button>
                      <Button onClick={HandleSave} color="info">
                        Save
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button onClick={() => setAddStaff(true)} color="info">
                  Add Client
                </Button>
              </GridItem>
            </GridContainer>
          )}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info">
                  <h4 className={classes.cardTitleWhite}>
                    List Of All Clients
                  </h4>
                  <p className={classes.cardCategoryWhite}>
                    All Clients are listed below
                  </p>
                </CardHeader>
                <CardBody>
                  {empty ? (
                    <EmptyTable />
                  ) : (
                    <Table
                      tableHeaderColor="info"
                      tableHead={["Sl.No", "Name", "Action"]}
                      tableData={ClientsData}
                      loading={loading}
                      noAction={false}
                      setEdit={setEdit}
                    />
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      )}
    </>
  );
}
