import React, { useEffect, useState, useRef } from "react";
// import { Domain } from "Domain";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "components/Table/Table1.js";
import Table1 from "@mui/material/Table";
// import autoTable from "jspdf-autotable";
// core components
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
// import axios from "axios";
// import Danger from "components/Typography/Danger";
// import ImageUpload from "components/CustomUpload/ImageUpload.js";
import "jspdf-autotable";
// import EmptyTable from "components/EmptyTable";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import ReactToPrint from "react-to-print";
// import DataTable from "components/Table/DataTable";
// import CustomFileInput from "components/CustomFileInput/CustomFileInput";
// import { AttachFile } from "@material-ui/icons";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import TextField from "@mui/material/TextField";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as XLSX from "xlsx";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
import Events2 from "views/Events/Events2";
import Events1 from "views/Events/Events1";
import Events from "views/Events/Events";
import Images from "assets/img/attributes.png";
// import Invoice from "components/reports/Invoice";
// import { PDFViewer } from "@react-pdf/renderer";
import CustomInput from "components/CustomInput/CustomInput";
// import Danger from "components/Typography/Danger";
// import { Link } from "react-router-dom";
import LogoImage from "assets/img/logo.png";
import LogoHeader from "assets/img/header.png";
import Signature from "assets/img/Signature.png";
import MuiTableCell from "@material-ui/core/TableCell";
import { Paper, TableContainer, TableHead, TableRow } from "@mui/material";

import { TableBody } from "@material-ui/core";
// import axios from "axios";
// import { Domain } from "Domain";

const styles = {
  tableRow: {
    height: 5,
  },
  tableCell: {
    padding: "0px 16px",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryGrey: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgb(128,128,128)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "500",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardStyle: {
    padding: "10px",
    paddingRight: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function WorkLog() {
  const TableCells = withStyles({
    root: {
      borderBottom: "none",
    },
  })(MuiTableCell);
  let componentRef = useRef();
  let componentRef2 = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [AddStaff, setAddStaff] = useState(false);

  const [Doctors, setDoctor] = useState("");
  const [Clients, setClient] = useState([]);
  const [FilteredData, setFilteredData] = useState([]);
  const [Open, setOpen] = useState(false);
  const [ClientsList, setClientsList] = useState([]);
  const [DoctorsList, setDoctorsList] = useState([]);
  const [JsonDataToInsert, setJsonDataToInsert] = useState([]);
  const [UpdatedData, setUpdatedData] = useState();
  const [Error, setError] = useState(false);
  const [Clientloaded, setLoadedClient] = useState(false);
  const [loaded, setLoaded] = useState(false);
  // const [ClickGenerate, setClickGenerate] = useState(false);

  // const [Pan, SetDocPan] = useState("");
  //Generate Price Function
  //console.log("UpdatedDAta:++++++++++", UpdatedData);
  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  async function saveAsExcel() {
    if (UpdatedData) {
      setError(false);
      let header = [
        "Sl.No",
        "Patient Name",
        "Modality",
        "Body Part",
        "Reported Time",
        "Day/Night",
        "Price",
      ];

      XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);

        const sheetData = getSheetData(UpdatedData, header);

        const totalColumns = sheetData[0].length;

        sheet1.cell("A1").value(sheetData);
        sheet1.column("A").width(10);
        sheet1.column("B").width(25);
        sheet1.column("C").width(25);
        sheet1.column("D").width(25);
        sheet1.column("E").width(25);
        sheet1.column("F").width(25);
        sheet1.column("G").width(25);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        sheet1.range("A1:" + endColumn + "1").style("fill", "d3d3d3");
        range.style("border", false);
        return workbook.outputAsync().then((res) => {
          saveAs(res, Doctors + "_invoice.xlsx");
        });
      });
    } else {
      setError(true);
    }
  }
  // function HandlePDF() {
  //   // window.print();
  //   var printwin = window.open("");
  //   printwin.document.write(document.getElementById("toprint").innerHTML);
  //   printwin.stop();
  //   printwin.print();
  //   printwin.close();
  // }

  // function SaveAsPDF() {
  //   if (UpdatedData) {
  //     setError(false);
  //     var doc = new jsPDF();
  //     var rows = [];

  //     let col = [
  //       "Sl.No",
  //       "Patient Name",
  //       "Modality",
  //       "Body Part",
  //       "Reported Time",
  //       "Day/Night",
  //       "Price",
  //     ];

  //     for (var key in UpdatedData) {
  //       var temp = [
  //         UpdatedData[key].id,
  //         UpdatedData[key].Name,
  //         UpdatedData[key].Modality,
  //         UpdatedData[key].BodyPart,
  //         UpdatedData[key].ReportedTime,
  //         UpdatedData[key].DayNight,
  //         UpdatedData[key].Price,
  //       ];
  //       // //console.log("iteration key:", key, "data:", data1[key]);
  //       rows.push(temp);
  //     }

  //     // autoTable(doc, { head: [col], body: [rows] });
  //     //console.log("Date:: ", new Date().toLocaleString().split(",")[0]);
  //     doc.autoTable(col, rows);
  //     doc.save(Doctors + "_invoice.pdf");
  //   } else {
  //     setError(true);
  //   }
  // }
  async function saveAsExcelClient() {
    if (UpdatedData) {
      let header = [
        "Sl.No",
        "Patient Name",
        "Modality",
        "Body Part",
        "No. of reports",
        "Dispatched Time",
        "Day/Night",
        "Price",
      ];

      XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const sheetData = getSheetData(UpdatedData, header);
        const totalColumns = sheetData[0].length;

        sheet1.cell("A1").value(sheetData);
        sheet1.column("A").width(10);
        sheet1.column("B").width(25);
        sheet1.column("C").width(25);
        sheet1.column("D").width(25);
        sheet1.column("E").width(25);
        sheet1.column("F").width(25);
        sheet1.column("G").width(25);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        sheet1.range("A1:" + endColumn + "1").style("fill", "d3d3d3");
        range.style("border", false);
        return workbook.outputAsync().then((res) => {
          saveAs(res, Clients + "_invoice.xlsx");
        });
      });
    }
  }

  //===================================

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        setJsonDataToInsert(json);
        //console.log("json:", json);
        // //console.log("JsonDataToInsert:", JsonDataToInsert);

        setDoctorsList(DistinctRecords(json, "Doctor"));
        setClientsList(DistinctRecords(json, "Client"));
        function DistinctRecords(MYJSON, prop) {
          return MYJSON.filter((obj, pos, arr) => {
            return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
          });
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  //console.log("Clients List: ", ClientsList);
  //console.log("Doctors List: ", DoctorsList);

  //console.log("Doctors:", Doctors);
  //console.log("Clients:", Clients);

  //console.log("FilteredData", FilteredData);

  useEffect(() => {
    if (Doctors != "") {
      setOpen(true);
      setFilteredData(
        JsonDataToInsert.filter((l) => {
          return l.Doctor.match(Doctors);
        })
      );
    } else if (Clients != "") {
      setOpen(true);
      setFilteredData(
        JsonDataToInsert.filter((l) => {
          return l.Client.match(Clients);
        })
      );
    } else {
      setOpen(false);
    }
  }, [Doctors, Clients]);
  function HandleBack() {
    setDocTotal(0);
    setTotal("");
    setInvoice("");
    setUpdatedData(false);
    setClient("");
    setDoctor("");
    setOpen(false);
    setInvoiceOpen(false);
  }

  const DoctorsData = DoctorsList.map((d) => ({
    Name: d.Doctor,
  }));

  // //console.log("DoctorsTDSData:", DoctorsTDSData);
  const ClientsData = ClientsList.map((d) => ({
    Name: d.Client,
  }));
  const FilteredData1 = FilteredData.map((d, key) => ({
    id: key + 1,
    Name: d.Name,
    Modality: d.Modality,
    BodyPart: d.BodyPart,
    ReportedTime: d.ReportedTime,
    DayNight:
      d.ReportedTime.split(" ")[1].split(":")[0] >= 7 &&
      d.ReportedTime.split(" ")[1].split(":")[0] < 23
        ? "Day"
        : "Night",
    Price: "Not Generated",
  }));
  //console.log("FilteredData1", FilteredData1);

  const FilteredData2 = FilteredData.map((d, key) => ({
    id: key + 1,
    Name: d.Name,
    Modality: d.Modality,
    BodyPart: d.BodyPart,
    NoOfReport: d.BodyPart.split(",").length,
    DispatchedTime: d.DispatchedTime,
    DayNight:
      d.DispatchedTime.split(" ")[1].split(":")[0] >= 7 &&
      d.DispatchedTime.split(" ")[1].split(":")[0] < 23
        ? "Day"
        : "Night",
    Price: "Not Generated",
  }));
  //console.log("FilteredData2", FilteredData2);

  //Download as PDF
  // function printDocument() {
  //   const input = document.getElementById("toprint");
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/JPEG");
  //     const pdf = new jsPDF();
  //     pdf.addImage(imgData, "JPEG", 0, 0);
  //     // pdf.output('dataurlnewwindow');
  //     pdf.save("download.pdf");
  //   });
  // }

  function HandleCansel() {
    setAddStaff(false);
    setClientsList([]);
    setDoctorsList([]);
    setJsonDataToInsert([]);
  }
  console.log("Error:", Error);
  console.log("DocTotal:::::", DocTotal);

  const [Total, setTotal] = useState(0);
  const [DocTotal, setDocTotal] = useState(0);
  // const [DocPan, setDocPan] = useState(0);
  const [InvoiceOpen, setInvoiceOpen] = useState(false);
  // const [TDSDataArray, setArray] = useState([]);
  // const [flag, setFlag] = useState(false);

  // useEffect(() => {
  //   //console.log("Inside USEEFFECT$$$$$$$$$$$$$");

  //   if (Doctors != "") {
  //     //console.log("Inside IF$$$$$$$$$$$$$");
  //     if (TDSDataArray.includes(DocPan)) {
  //       console.log("TDS added Already");
  //     } else {
  //       const DoctorsTDSData = {
  //         Name: Doctors,
  //         TotalAmount: DocTotal,
  //         TDS: parseFloat(DocTotal) * 0.1,
  //         Amount: DocTotal - parseFloat(DocTotal) * 0.1,
  //         Pan: DocPan,
  //         StringId: Doctors.replace(/ /g, "")
  //           .replace(/[^a-zA-Z0-9 ]/g, "")
  //           .toLowerCase(),
  //       };
  //       setArray((oldArray) => [...oldArray, DoctorsTDSData]);
  //     }
  //   }
  // }, [Doctors]);
  //console.log("DoctorsTDSDataAfter_Map:", TDSDataArray);

  function OpenInvoice() {
    setInvoiceOpen(true);
  }
  const [invoice, setInvoice] = useState({
    invoice_no: "",
    address: "",
    trans_date: "",
    due_date: "",
    balance: "",
    Discount: "",
    DelayCharges: "",
  });
  //Function to handle Data input
  function HandleData(e) {
    const newData = { ...invoice };
    newData[e.target.id] = e.target.value;
    setInvoice(newData);
    console.log(newData);
  }
  // const [invoice, setInvoice] = useState("");
  // function HandleInvoice() {
  //   setInvoiceOpen(false);
  //   setInvoice({
  //     id: "invoice_001",
  //     invoice_no: data.invoice_no,
  //     balance: "",
  //     company: Clients,
  //     email: "",
  //     phone: "",
  //     address: data.address,
  //     trans_date: data.trans_date,
  //     due_date: data.due_date,
  //     items: [
  //       {
  //         sno: 1,
  //         desc: "Radiology Reporting CT & MRI cases",
  //         qty: FilteredData2.length,
  //         rate: Total,
  //       },
  //     ],
  //   });
  // }
  useEffect(() => {
    if (Doctors != "") {
      setLoaded(true);
    }
    if (Clients != "") {
      setLoadedClient(true);
    }
  }, [Doctors, Clients]);
  // useEffect(() => {
  //   console.log("inside TDS");
  //   if (DocTotal != 0) {
  //     console.log("inside TDS1");

  //     let formData = new FormData();
  //     formData.append("Id", 0);
  //     formData.append("DoctorName", Doctors);
  //     formData.append("Total", DocTotal);
  //     formData.append("TDS", DocTotal * 0.1);
  //     formData.append("Amount", DocTotal - DocTotal * 0.1);
  //     axios({
  //       method: "post",
  //       url: Domain + "/SaveTDS_data.php",
  //       data: formData,
  //       config: { headers: { "Content-Type": "multipart/form-data" } },
  //     })
  //       .then(function (response) {
  //         //handle success
  //         // //console.log("\n \t GetAllModalities:", response);
  //         if (response.data.success) {
  //           console.log("Success");
  //         }
  //       })
  //       .catch(function (response) {
  //         //handle error
  //         console.log(response);
  //       });
  //   }
  // }, [DocTotal]);
  return (
    <>
      <Snackbar
        place="bc"
        color="success"
        icon={AddAlert}
        message="WorkLog fetched successfully!"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="bc"
        color="danger"
        icon={AddAlert}
        message="Error! Please try again"
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />
      {Open ? (
        <>
          {Doctors != "" ? (
            <>
              <Button onClick={HandleBack}>Back</Button>
              <Button onClick={saveAsExcel} color="success">
                Download Excel
              </Button>
              {/* <Button onClick={SaveAsPDF} color="danger">
                Download PDF
              </Button> */}
              <ReactToPrint
                trigger={() => <Button color="danger">Download PDF</Button>}
                content={() => componentRef}
              />
              <div style={{ display: "none" }}>
                <div ref={(el) => (componentRef = el)}>
                  <img src={LogoHeader} alt="" width="100%" />
                  <br />
                  <Card>
                    <div style={{ background: "#c0c0c0" }}>
                      <CardHeader color="default">
                        <b>
                          <h4> {Doctors}</h4>Total Number of Cases:{" "}
                          {FilteredData1.length} <br />
                        </b>
                      </CardHeader>{" "}
                    </div>
                    <Events
                      data={FilteredData1}
                      Entity={Doctors}
                      setData={setUpdatedData}
                      loaded={loaded}
                      setTotal={setDocTotal}
                    />
                    <CardBody>
                      {UpdatedData ? (
                        <>
                          <Table
                            tableHeaderColor="info"
                            tableHead={[
                              "Sl.No",
                              "Name",
                              "Modality",
                              "Body Part",
                              "Reported Time",
                              "Day/Night",
                              "Price",
                            ]}
                            tableData={UpdatedData}
                            loading={false}
                            noAction={true}
                          />
                          <br />

                          <GridContainer
                            direction="row"
                            alignItems="center"
                            justify="flex-end"
                          >
                            <GridItem xs={2} sm={2} md={2}>
                              Total Earnings :
                              <br />
                              {" TDS(10%) :"} <br />
                              <b>Total amount :</b>
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2}>
                              {DocTotal}.00/- <br />
                              {parseFloat(DocTotal) * 0.1}.00/-
                              <br />
                              <b>
                                {parseInt(DocTotal) -
                                  parseFloat(DocTotal) * 0.1}
                                .00/-
                              </b>
                            </GridItem>
                          </GridContainer>
                        </>
                      ) : (
                        <>
                          <Table
                            tableHeaderColor="info"
                            tableHead={[
                              "Sl.No",
                              "Name",
                              "Modality",
                              "Body Part",
                              "Reported Time",
                              "Day/Night",
                              "Price",
                            ]}
                            tableData={FilteredData1}
                            loading={false}
                            noAction={true}
                          />
                        </>
                      )}
                    </CardBody>
                  </Card>
                </div>
              </div>
              <Card>
                <CardHeader color="success">
                  <h4>Doctor - {Doctors}</h4>Total Number of Cases:{" "}
                  {FilteredData1.length} <br />
                </CardHeader>{" "}
                <Events
                  data={FilteredData1}
                  Entity={Doctors}
                  setData={setUpdatedData}
                  loaded={loaded}
                  setTotal={setDocTotal}
                />
                <CardBody>
                  {UpdatedData ? (
                    <>
                      <Table
                        tableHeaderColor="info"
                        tableHead={[
                          "Sl.No",
                          "Name",
                          "Modality",
                          "Body Part",
                          "Reported Time",
                          "Day/Night",
                          "Price",
                        ]}
                        tableData={UpdatedData}
                        loading={false}
                        noAction={true}
                      />
                      <br />
                      <GridContainer
                        direction="row"
                        alignItems="center"
                        justify="flex-end"
                      >
                        <GridItem xs={2} sm={2} md={2}>
                          Total Earnings :
                          <br />
                          {" TDS(10%) :"} <br />
                          <b>Total amount :</b>
                        </GridItem>
                        <GridItem xs={1} sm={1} md={1}>
                          {DocTotal}.00/- <br />
                          {parseFloat(DocTotal) * 0.1}.00/-
                          <br />
                          <b>
                            {parseInt(DocTotal) - parseFloat(DocTotal) * 0.1}
                            .00/-
                          </b>
                        </GridItem>
                      </GridContainer>
                    </>
                  ) : (
                    <>
                      <Table
                        tableHeaderColor="info"
                        tableHead={[
                          "Sl.No",
                          "Name",
                          "Modality",
                          "Body Part",
                          "Reported Time",
                          "Day/Night",
                          "Price",
                        ]}
                        tableData={FilteredData1}
                        loading={false}
                        noAction={true}
                      />
                    </>
                  )}
                </CardBody>
              </Card>
            </>
          ) : (
            <> </>
          )}
          {Clients != "" ? (
            <>
              <Button onClick={HandleBack}>Back</Button>
              <Button onClick={saveAsExcelClient} color="success">
                Download Excel
              </Button>
              <Button onClick={OpenInvoice} color="danger">
                Generate Invoice
              </Button>
              {InvoiceOpen ? (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        onChange={(e) => HandleData(e)}
                        value={invoice.invoice_no}
                        labelText="Invoice Number"
                        id="invoice_no"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        onChange={(e) => HandleData(e)}
                        value={invoice.trans_date}
                        labelText="Invoice Date (dd/mm/yyyy To dd/mm/yyy)"
                        id="trans_date"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        onChange={(e) => HandleData(e)}
                        value={invoice.due_date}
                        labelText="Due Date"
                        id="due_date"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        onChange={(e) => HandleData(e)}
                        value={invoice.address}
                        labelText="Client Address (Optional)"
                        id="address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        onChange={(e) => HandleData(e)}
                        value={invoice.balance}
                        labelText="Previous Balance"
                        id="balance"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        onChange={(e) => HandleData(e)}
                        value={invoice.DelayCharges}
                        labelText="Delay Charges"
                        id="DelayCharges"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        onChange={(e) => HandleData(e)}
                        value={invoice.Discount}
                        labelText="Discount"
                        id="Discount"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <ReactToPrint
                    trigger={() => (
                      <Button color="danger">Download Invoice</Button>
                    )}
                    content={() => componentRef2}
                  />
                  <div style={{ display: "none" }}>
                    <div ref={(el) => (componentRef2 = el)}>
                      <img src={LogoImage} alt="" width="100%" />
                      <br />
                      <div
                        style={{ background: "#C0C0C0", textAlign: "center" }}
                      >
                        <span
                          style={{
                            color: "black",
                            fontFamily: "sans-serif",
                          }}
                        >
                          <b>INVOICE</b>
                        </span>
                      </div>
                      <br />
                      <GridContainer
                        direction="row"
                        alignItems="right"
                        justify="flex-end"
                      >
                        <GridItem xs={4} sm={4} md={4}>
                          <b>{Clients}</b>
                          <br />
                          {invoice.address}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                          Invoice Number:
                          <br />
                          Invoice Date:
                          <br />
                          Bill Period:
                          <br />
                          Due Date:
                          <br />
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                          <span style={{ textAlign: "right" }}>
                            {invoice.invoice_no}
                            <br />
                            {new Date().toDateString()}
                            <br />
                            {invoice.trans_date}
                            <br />
                            {invoice.due_date}
                          </span>
                        </GridItem>
                      </GridContainer>
                      <br />

                      {/* //////////////////// */}
                      <br />
                      <br />
                      <TableContainer component={Paper}>
                        <Table1
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                        >
                          <TableHead style={{ background: "#c0c0c0" }}>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <MuiTableCell>Item</MuiTableCell>
                              <MuiTableCell align="right">Qty</MuiTableCell>
                              <MuiTableCell align="right">Amount</MuiTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <MuiTableCell component="th" scope="row">
                                Radiology Reporting X-ray, CT & MRI cases
                              </MuiTableCell>
                              <MuiTableCell align="right">
                                {FilteredData2.length}
                              </MuiTableCell>
                              <MuiTableCell align="right">
                                ₹ {Total}.00/-
                              </MuiTableCell>
                            </TableRow>

                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <MuiTableCell rowSpan={2} align="right">
                                <div
                                  style={{ background: "#e3e3e3", padding: 4 }}
                                >
                                  <Table1
                                    sx={{ minWidth: 300 }}
                                    aria-label="simple table"
                                  >
                                    <TableBody>
                                      <TableRow
                                        className={classes.tableRow}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCells
                                          className={classes.tableCell}
                                        >
                                          Previous Balance
                                        </TableCells>
                                        <TableCells
                                          className={classes.tableCell}
                                        >
                                          : ₹{" "}
                                          {invoice.balance == "" ||
                                          invoice.balance == 0 ? (
                                            <>0.00</>
                                          ) : (
                                            <>{invoice.balance}.00</>
                                          )}
                                        </TableCells>
                                      </TableRow>
                                      <TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCells
                                          className={classes.tableCell}
                                        >
                                          Delay Charges
                                        </TableCells>
                                        <TableCells
                                          className={classes.tableCell}
                                        >
                                          : ₹{" "}
                                          {invoice.DelayCharges == "" ||
                                          invoice.DelayCharges == 0 ? (
                                            <>0.00</>
                                          ) : (
                                            <>{invoice.DelayCharges}.00</>
                                          )}
                                        </TableCells>
                                      </TableRow>
                                      <TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCells
                                          className={classes.tableCell}
                                        >
                                          Discount
                                        </TableCells>
                                        <TableCells
                                          className={classes.tableCell}
                                        >
                                          : ₹{" "}
                                          {invoice.Discount == "" ||
                                          invoice.Discount == 0 ? (
                                            <>0.00</>
                                          ) : (
                                            <>{invoice.Discount}.00</>
                                          )}
                                        </TableCells>
                                      </TableRow>
                                      <TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCells
                                          className={classes.tableCell}
                                        >
                                          <b>Total Dues</b>
                                        </TableCells>
                                        <TableCells
                                          className={classes.tableCell}
                                        >
                                          <b>
                                            : ₹{" "}
                                            {invoice.balance == "" ||
                                            invoice.balance == 0 ? (
                                              <>{Total}</>
                                            ) : (
                                              <>{parseFloat(invoice.balance)}</>
                                            )}
                                            .00
                                          </b>
                                        </TableCells>
                                      </TableRow>
                                    </TableBody>
                                  </Table1>
                                </div>
                              </MuiTableCell>
                              <MuiTableCell align="right">
                                <b>Sub Total</b>
                              </MuiTableCell>

                              <MuiTableCell align="right">
                                <b>: ₹ {Total}.00/-</b>
                              </MuiTableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <MuiTableCell align="right">
                                <b>Total</b>
                              </MuiTableCell>

                              <MuiTableCell align="right">
                                <b>
                                  : ₹{" "}
                                  {invoice.balance == "" ||
                                  invoice.balance == 0 ? (
                                    <>{Total}</>
                                  ) : (
                                    <>
                                      {parseFloat(Total) +
                                        parseFloat(invoice.balance)}
                                    </>
                                  )}
                                  .00/-
                                </b>
                              </MuiTableCell>
                            </TableRow>
                          </TableBody>
                        </Table1>
                      </TableContainer>
                      <br />
                      <GridContainer>
                        <GridItem xs={8} sm={8} md={8}>
                          <div
                            style={{
                              background: "#e3e3e3",
                            }}
                          >
                            <div
                              style={{
                                background: "#C0C0C0",
                                textAlign: "center",
                              }}
                            >
                              <b> MAKE PAYMENTS TO</b>
                            </div>
                            <br />
                            <Table1
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableBody>
                                <TableRow
                                  className={classes.tableRow}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCells className={classes.tableCell}>
                                    Beneficiary Name
                                  </TableCells>
                                  <TableCells className={classes.tableCell}>
                                    : INTHC Solutions Private Limited
                                  </TableCells>
                                </TableRow>
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCells className={classes.tableCell}>
                                    A/C Number
                                  </TableCells>
                                  <TableCells className={classes.tableCell}>
                                    : 210705000804
                                  </TableCells>
                                </TableRow>
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCells className={classes.tableCell}>
                                    IFSC Code
                                  </TableCells>
                                  <TableCells className={classes.tableCell}>
                                    : ICIC0002107
                                  </TableCells>
                                </TableRow>
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCells className={classes.tableCell}>
                                    SWIFT Code
                                  </TableCells>
                                  <TableCells className={classes.tableCell}>
                                    : ICICINBBXXX
                                  </TableCells>
                                </TableRow>
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCells className={classes.tableCell}>
                                    Bank Branch
                                  </TableCells>
                                  <TableCells className={classes.tableCell}>
                                    : ICICI Bank, Pandalam
                                  </TableCells>
                                </TableRow>
                              </TableBody>
                            </Table1>
                          </div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                          <img
                            align="right"
                            width="200px"
                            src={Signature}
                            alt=""
                          />
                        </GridItem>
                      </GridContainer>
                      <div
                        style={{
                          textAlign: "center",
                          padding: 1,
                        }}
                      >
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <p
                          style={{
                            color: "black",
                            fontSize: 15,
                          }}
                        >
                          {" "}
                          For any discrepancy please contact INTHC Solutions
                          Private Limited. Email:
                          Telemedhealthcaresolutions@gmail.com | Phone: +91
                          7994712111 | Website:
                          www.Telemedhealthcaresolutions.com
                        </p>
                      </div>
                      {/* //////////////////// */}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {/* {invoice != "" ? (
                <>
                  {" "}
                  <PDFViewer width="100%" height="600" className="app">
                    <Invoice invoice={invoice} />
                  </PDFViewer>
                </>
              ) : (
                <></>
              )} */}
              <Card>
                <CardHeader color="success">
                  <h4>
                    <b>Client - {Clients}</b>
                  </h4>
                  Total Number of Cases: {FilteredData2.length} <br />
                  Total Amount : {Total}.00/-
                </CardHeader>
                <Events1
                  data={FilteredData2}
                  Entity={Clients}
                  setData={setUpdatedData}
                  setTotal={setTotal}
                  loaded={Clientloaded}
                />
                <CardBody>
                  {UpdatedData ? (
                    <>
                      <Table
                        tableHeaderColor="info"
                        tableHead={[
                          "Sl.No",
                          "Name",
                          "Modality",
                          "Body Part",
                          "No. Of report",
                          "Dispatched Time",
                          "Day/Night",
                          "Price",
                        ]}
                        tableData={UpdatedData}
                        loading={false}
                        noAction={true}
                      />
                    </>
                  ) : (
                    <>
                      <Table
                        tableHeaderColor="info"
                        tableHead={[
                          "Sl.No",
                          "Name",
                          "Modality",
                          "Body Part",
                          "No. Of report",
                          "Dispatched Time",
                          "Day/Night",
                          "Price",
                        ]}
                        tableData={FilteredData2}
                        loading={false}
                        noAction={true}
                      />
                    </>
                  )}
                </CardBody>
              </Card>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {AddStaff ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <form>
                    <CardHeader color="info">
                      <h4 className={classes.cardTitleWhite}>Upload Excel</h4>
                      <p className={classes.cardCategoryWhite}>
                        Select the excel file from your system
                      </p>
                    </CardHeader>

                    <CardBody>
                      <Card className={classes.cardStyle}>
                        <GridContainer>
                          <GridItem xs={6} sm={6} md={6}>
                            <form>
                              <label htmlFor="upload">Upload File</label>
                              <input
                                type="file"
                                name="upload"
                                id="upload"
                                onChange={readUploadFile}
                              />
                            </form>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <img src={Images} alt="" />
                        </GridContainer>
                      </Card>
                    </CardBody>
                    <CardFooter>
                      <Button onClick={HandleCansel} color="danger">
                        Cancel
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          ) : (
            <>
              <GridContainer justify="center">
                <Button onClick={() => setAddStaff(true)} color="info">
                  Upload Excel
                </Button>
              </GridContainer>
            </>
          )}
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <Card>
                <CardHeader color="info">Doctors List</CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="info"
                    tableHead={["Name", "Action"]}
                    tableData={DoctorsData}
                    loading={false}
                    noAction={false}
                    setEdit={setDoctor}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Card>
                <CardHeader color="info">Clients List</CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="info"
                    tableHead={["Name", "Action"]}
                    tableData={ClientsData}
                    loading={false}
                    noAction={false}
                    setEdit={setClient}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info">Doctors TDS Data</CardHeader>
                <CardBody>
                  <Events2 JsonData={JsonDataToInsert} Doctors={DoctorsData} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      )}
    </>
  );
}
