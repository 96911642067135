import { Container } from "@material-ui/core";
import ReactToPrint from "react-to-print";

import { Domain } from "Domain";
import axios from "axios";
import CustomInput from "components/CustomInput/CustomInput";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import LogoHeader from "assets/img/logo_transp.png";
import Table from "components/Table/Table3.js";
import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
import SingleSelect from "components/SingleSelect";

// import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader";
import image from "assets/img/bg_img.jpg";
// import CardBody from "components/Card/CardBody";
// import Product2 from "assets/img/pickle.jpeg";
// import Product from "assets/img/strawberry.jpg";
// import CardFooter from "components/Card/CardFooter";
export default function PriceListPage() {
  const [PriceData, setPriceData] = useState([]);
  const [PriceData1, setPriceData1] = useState([]);
  let componentRef = useRef();
  const TypeOfModality = [
    { value: 1, label: "CT/X-RAY PROCEEDURES" },
    { value: 2, label: "MRI PROCEEDURES" },
  ];
  const [SelectedValue, SetSelectedValue] = React.useState({
    Id: null,
    label: "",
  });
  console.log(SelectedValue);
  useEffect(() => {
    //API call for get latest 10 elements

    let formData = new FormData();
    formData.append("Status", "Active");
    if (SelectedValue.Id === 1) formData.append("Type", "CT");
    else formData.append("Type", "MR");
    axios({
      method: "post",
      url: Domain + "/GetAllPriceData.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("PriceData Response:", response.data);
        if (response.data.TotalCount != 0) {
          setPriceData(response.data.data);
        }
      })
      .catch(function (response) {
        //handle error
        // showDeletedNotification();
        console.log(response);
      });
  }, [SelectedValue.Id]);
  console.log("PriceData1:", PriceData1);
  function HandleData(e) {
    const newData = { ...PriceData };
    newData[e.target.id]["Price"] = e.target.value;
    setPriceData1(newData);
    console.log("NewData:", newData);
  }
  function HandleData1(e) {
    const newData = { ...PriceData };
    newData[e.target.id]["Proceedure"] = e.target.value;
    setPriceData1(newData);
    console.log("NewData:", newData);
  }
  const [Print, setPrint] = useState([]);
  // const [Print1, setPrint1] = useState([]);
  // useEffect(() => {
  //   const Print = PriceData.map((d) => ({
  //     Proceedure: d.Proceedure,
  //     Price: d.Price,
  //   }));
  //   setPrint(Print);
  // }, [PriceData]);
  useEffect(() => {
    if (PriceData1.length > 0) {
      const Print = PriceData1.map((d) => ({
        Proceedure: d.Proceedure,
        Price: d.Price,
      }));
      setPrint(Print);
    } else {
      const Print = PriceData.map((d) => ({
        Proceedure: d.Proceedure,
        Price: d.Price,
      }));
      setPrint(Print);
    }
  }, [PriceData1, PriceData]);
  return (
    <Container>
      <Card>
        <SingleSelect
          noOptionsMessage="No modalities"
          placeholder="Select Modality"
          Options={TypeOfModality}
          setValue={SetSelectedValue}
          formControlProps={{
            fullWidth: true,
          }}
        />
        {SelectedValue.Id ? (
          <>
            <ReactToPrint
              documentTitle={
                SelectedValue.Id == 1
                  ? "THS CT STUDY PRICE LIST"
                  : "THS MRI PRICE LIST"
              }
              trigger={() => <Button color="danger">Download PDF</Button>}
              content={() => componentRef}
            />
            <br />
            <br />
            <CardHeader color="info">
              <GridContainer
                direction="row"
                alignItems="center"
                justify="flex-end"
              >
                <GridItem xs={6} sm={6} md={6}>
                  {SelectedValue.Label}
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  Price
                </GridItem>
              </GridContainer>
            </CardHeader>

            {PriceData.map((item, key) => {
              return (
                <div key={key}>
                  <GridContainer
                    direction="row"
                    alignItems="center"
                    justify="flex-end"
                  >
                    <GridItem xs={6} sm={6} md={6}>
                      {" "}
                      <CustomInput
                        onChange={(e) => HandleData1(e)}
                        value={item.Proceedure}
                        id={key}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      {" "}
                      <CustomInput
                        onChange={(e) => HandleData(e)}
                        value={item.Price}
                        id={key}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </div>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </Card>

      <div style={{ display: "none" }}>
        <div
          ref={(el) => (componentRef = el)}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <center>
            <img src={LogoHeader} alt="" width="250" />
            <h3>
              <b>Telemed Healthcare Solutions</b>
            </h3>
            <span style={{ fontSize: 24, marginBottom: 0, marginTop: 0 }}>
              <b> Day Coverage 8 AM - 11 PM</b>
            </span>
          </center>
          <Container style={{ padding: 50, paddingTop: 0, paddingBottom: 7 }}>
            <span style={{ fontSize: 25 }}>
              <Table
                tableHeaderColor="info"
                tableHead={[SelectedValue.Label, "INR"]}
                tableData={Print}
                loading={false}
                noAction={true}
              />
            </span>
            <p>
              <span style={{ fontSize: 19 }}>
                <b> Please Note:-</b> <br />
                For night hawk support between 11pm-8am, Rs. 20/- for X-ray, Rs.
                100/- for CT and Rs. 150/- for MRI, additional per study shall
                be charged. Billing will be on 30-day cycle, non-payment within
                7 days will result in termination of services. Penalty and
                interest will be charges as per MSMEact. After successful
                contract of 1 year there will be a hike of 12% to the clients.
              </span>
            </p>
          </Container>
        </div>
      </div>
    </Container>
  );
}
